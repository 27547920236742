
export const AppConfig = {
    CONFIGURATION: {
        APP_MODE: "production",
        APP_DEVELOPMENT: "development",
        APP_LOCALHOST: "localhost",
        MONITOR_PRODUCTID_FOR_PRODUCTION: "61d381097b96c64d52408326",
        CANADA_LAPTOP_PRODUCTID_FOR_PRODUCTION: "643d48a34cbe5146ecd6bdc5",
        MONITOR_PRODUCTID_FOR_STAGING: "6539f109090cb07ba2a804f3",
        CANADA_LAPTOP_PRODUCTID_FOR_STAGING: "6539f109090cb07ba2a804fb",
        SENTRY_URL: 'https://04b0925a04fc87093de058b4100f3243@o4506381490978816.ingest.sentry.io/4506381491044352',
        SQS_URL: "https://retriever.qa.indous.in/api/validate_sqs_addresses/",
        SQS_URL_PROD: "https://app.helloretriever.com/api/validate_sqs_addresses/",
        TERM_PAGE_URL: "https://helloretriever.com/terms",
        PRIVACY_PAGE_URL: "https://helloretriever.com/privacy",
        APP_EMAILID: "help@helloretriever.com",
        APP_BILLING_EMAILID: "billing@helloretriever.com",
        APP_BILLING_SUBJECT: "Invoice Question for Retriever"
    }
}