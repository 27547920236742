import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import Logo from "../Logo";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: "#001e42",
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props: { onSidebarMobileOpen: () => void }) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64, a: { display: "flex" } }}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 140,
                path: {
                  fill: "white",
                },
              }}
            />
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
