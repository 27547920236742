import { createContext, useEffect, useState } from "react";
import axios from "axios";

export type AuthState =
  | {
    isInitialized: boolean;
    isAuthenticated: true;
    user: {
      email: string;
      role: "member" | "admin";
      orgName?: string;
      is_warehouse_user?: boolean;
      sidebar?: boolean; // Show API page in sidebar.
    };
  }
  | {
    isInitialized: boolean;
    isAuthenticated: false;
  };

export type AuthContextValue = {
  state: AuthState;
  login: (email: string) => Promise<void>;
  logout: () => Promise<void>;
};

const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
};

const AuthContext = createContext<AuthContextValue>({
  state: initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: React.FC = (props: any) => {
  const { children } = props;
  const [authState, setAuthState] = useState<AuthState>(initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get("/auth_state/");
        const { isAuthenticated, email, role, orgName, sidebar, is_warehouse_user } = response.data;

        if (isAuthenticated) {
          setAuthState({
            isInitialized: true,
            isAuthenticated: true,
            user: {
              email,
              role,
              orgName,
              sidebar,
              is_warehouse_user,
            },
          });
        } else {
          setAuthState({
            isInitialized: true,
            isAuthenticated: false,
          });
        }
      } catch (err) {
        console.error(err);
        setAuthState({
          isInitialized: true,
          isAuthenticated: false,
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string) => {
    const data = new FormData();
    data.append("email", email);
    await axios.post("/api/send_magic_link/", data);
  };

  const logout = async () => {
    await axios.post("/logout/");
    setAuthState({
      isInitialized: true,
      isAuthenticated: false,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        state: authState,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
