import { useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import GlobalStyles from "./components/GlobalStyles";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { createTheme } from "./theme";
import OutdatedAppChunkLoadBoundary from "./utils/errorBoundary";

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();


  const theme = createTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <OutdatedAppChunkLoadBoundary>
          <SnackbarProvider dense maxSnack={3}>
            <GlobalStyles />
            {auth.state.isInitialized ? content : <SplashScreen />}
          </SnackbarProvider>
        </OutdatedAppChunkLoadBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;
