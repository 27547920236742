import { lazy, Suspense } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import { NotFoundErrorBoundary } from "./utils/errorBoundary";

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// Invite pages
const AcceptInvite = Loadable(lazy(() => import("./pages/authentication/AcceptInvite")));

// Retail order pages
const CancelOrder = Loadable(lazy(() => import("./pages/retail/CancelOrder")));

// Support pages
const EmployeeSupport = Loadable(lazy(() => import("./pages/support/EmployeeSupport")));

// Dashboard pages
const Dashboard = Loadable(lazy(() => import("./pages/dashboard/Dashboard.tsx")));
const NewLaptopReturn = Loadable(lazy(() => import("./pages/dashboard/NewLaptopReturn.tsx")));
const Settings = Loadable(lazy(() => import("./pages/dashboard/Settings")));
const Users = Loadable(lazy(() => import("./pages/dashboard/Users")));
const Billing = Loadable(lazy(() => import("./pages/dashboard/Billing")));
const ApiView = Loadable(lazy(() => import("./pages/dashboard/ApiView")));
const OktaView = Loadable(lazy(() => import("./pages/dashboard/OktaView")));
const Warehousing = Loadable(lazy(() => import("./pages/dashboard/Warehousing")))
const AzureView = Loadable(lazy(() => import("./pages/dashboard/AzureView")))

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "invite",
    children: [
      {
        path: "accept/:inviteCode",
        element: <AcceptInvite />,
      },
    ],
  },
  {
    path: "order",
    children: [
      {
        path: "cancel",
        element: <CancelOrder />,
      },
    ],
  },
  {
    path: "support",
    children: [
      {
        path: "devicereturn/:orderNumber/:supportHash",
        element: (
          <NotFoundErrorBoundary>
            <EmployeeSupport />
          </NotFoundErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "ssofailed",
    element: <Login />
  },
  {
    path: "oktafailed",
    element: <Login />
  },
  {
    path: "*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/*",
        element: <Dashboard />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "api",
        element: <ApiView />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
      {
        path: "oktaview",
        element: <OktaView />,
      },
      {
        path: "azureview",
        element: <AzureView />,
      },
      {
        path: "devicereturn",
        element: <NewLaptopReturn />,
      },
      {
        path: "warehousing",
        element: <Warehousing />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
