import { GlobalStyles as MuiGlobalStyles } from "@mui/material";

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
        a: {
          textDecoration: "none",
          color: "#007a8a",
          fontWeight: 600,
          letterSpacing: "0.1px",
        },
        button: {
          letterSpacing: "0.1px",
        },
        ".MuiTypography-caption": {
          letterSpacing: "0.1px",
        },
      }}
    />
  );
};

export default GlobalStyles;
