import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Box, FormHelperText, TextField, Divider, Typography } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { LoadingButton } from "@mui/lab";
import GSNormal from "../../../assets/btn-google-signin-normal.png";
import GSHover from "../../../assets/btn-google-signin-hover.png";
import GSFocused from "../../../assets/btn-google-signin-focus.png";
// import OKTANormal from "../../../assets/okta_login.png";

const LoginPasswordless = () => {

  const [ssofailed, setSsofailed] = useState(false);
  useEffect(() => {
    if (window.location.search.includes('ssofailed')) {
      setSsofailed(true);
    }
  }, []);

  //  code for okta integration
  const [oktafailed, setOktafailed] = useState(false);
  useEffect(() => {
    if (window.location.search.includes('oktafailed')) {
      setOktafailed(true);
    }
  }, []);

  // code for azure integration
  const [azurefailed, setAzurefailed] = useState(false);
  useEffect(() => {
    if (window.location.search.includes('azurefailed')) {
      setAzurefailed(true);
    }
  }, []);

  const isMountedRef = useIsMountedRef();
  const { login } = useAuth();
  const [emailSent, setEmailSent] = useState<string>();

  const tokenExpired = new URLSearchParams(useLocation().search).has("token_expired");
  useEffect(() => {
    const clearParams = () => {
      const newUrl = window.location.href.split('?')[0]; // Remove query string
      window.history.replaceState({}, '', newUrl);
    };

    clearParams();

    // Attach a 'beforeunload' event listener to clear params on refresh
    window.addEventListener('beforeunload', clearParams);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('beforeunload', clearParams);
    };
  }, []);
  if (emailSent) {
    return (
      <Box sx={{ mt: 1 }}>
        <Alert severity="info">
          <div>
            We&apos;ve sent an email to <b>{emailSent}</b> with a link to log in.
          </div>
        </Alert>
      </Box>
    );
  }

  function GoogleSignIn() {
    const [over, setOver] = useState(GSNormal);
    return (
      <a
        onMouseOver={() => setOver(GSHover)}
        onMouseOut={() => setOver(GSNormal)}
        onFocus={() => setOver(GSFocused)}
        style={{ textAlign: 'center', display: 'block' }}
        href="/sso/login/"
      >
        <img
          src={over}
          alt="Sign in with Google"
          width="200"
        />
      </a >
    )
  }

  // function OktaSingIn() {
  //   const [over] = useState(OKTANormal);
  //   return (
  //     <a
  //       // onMouseOut={() => setOver(OKTANormal)}
  //       style={{ textAlign: 'center', display: 'block', marginLeft: "15px" }}
  //       href="/sso/okta/login/"
  //     >
  //       <img
  //         src={over}
  //         alt="Sign in with OKTA"
  //         width="200"
  //       />
  //     </a >
  //   )
  // }

  return (
    <Formik
      initialValues={{
        email: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }

          setEmailSent(values.email);
        } catch (err: any) {
          console.error(err);
          if (isMountedRef.current) {
            const msg = err.response && err.response.data ? err.response.data : err.message;
            setStatus({ success: false });
            setErrors({ submit: msg });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {tokenExpired && (
            <Box sx={{ mt: 1 }}>
              <Alert severity="error">
                The login link you clicked has expired or was invalid. Please enter your email address and we'll send
                you a new one.
              </Alert>
            </Box>
          )}
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log in
            </LoadingButton>
            <Divider sx={{ mt: 3, mx: 10, mb: 2 }} />
            {ssofailed && (
              <>
                <Typography color="red" variant="body2" align="center" sx={{ mb: 5 }}>
                  <strong>
                    This Google account is not associated with a Retriever account
                  </strong>
                </Typography>
              </>
            )}
            {oktafailed && (
              <>
                <Typography color="red" variant="body2" align="center" sx={{ mb: 5 }}>
                  <strong>
                  Your organization enforces Okta SSO. Please log in to Retriever through your organization's Okta dashboard.
                  </strong>
                </Typography>
              </>
            )}
            {azurefailed && (
              <>
                <Typography color="red" variant="body2" align="center" sx={{ mb: 5 }}>
                  <strong>
                  Your organization enforces Azure SSO. Please log in to Retriever through your organization's Azure dashboard.
                  </strong>
                </Typography>
              </>
            )}
            <Typography color="textSecondary" variant="body2" align="center" sx={{ mb: 2 }}>
              OR
            </Typography>
            <Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <GoogleSignIn />
              {/* <OktaSingIn /> */}
            </Box>
          </Box>
          <Box sx={{ pl: 2, mt: 2 }}>
            <Typography color="textSecondary" variant="body2">
              Not yet an Enterprise Customer?{" "}
              <a href="https://helloretriever.com/getstarted" target="_blank" rel="noreferrer">
                Click here
              </a>{" "}
              (no fees or extra charges)
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginPasswordless;
