import React, { useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import App from "./App";
import { AuthProvider } from "./contexts/PasswordlessContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import * as serviceWorker from "./serviceWorker";
import { AppConfig } from "./constants/AppConfig";

// Use Django's CSRF cookie.
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const sentryURL = AppConfig.CONFIGURATION.SENTRY_URL

if (process.env.NODE_ENV !== AppConfig.CONFIGURATION.APP_DEVELOPMENT) {
  Sentry.init({
    dsn: { sentryURL },
    release: window.release_version,
  });
}

const ScriptTag = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `
      (function(d,u,ac) {
        var s=d.createElement('script');
        s.type='text/javascript';
        s.src='https://a.omappapi.com/app/js/api.min.js';
        s.async=true;
        s.dataset.user=u;
        s.dataset.account=ac;
        d.getElementsByTagName('body')[0].appendChild(s);
      })(document, 317435, 336530);
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

const ExtendScriptTag = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `
      (function() {
        var s = document.createElement('script');
        var b = document.querySelector('body') || document.body;
        s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        s.async = true;
        s.onload = function() {
          acsbJS.init({
            statementLink: '',
            footerHtml: '',
            hideMobile: false,
            hideTrigger: false,
            disableBgProcess: false,
            language: 'en',
            position: 'left',
            leadColor: '#001e42',
            triggerColor: '#001e42',
            triggerRadius: '50%',
            triggerPositionX: 'right',
            triggerPositionY: 'bottom',
            triggerIcon: 'people',
            triggerSize: 'medium',
            triggerOffsetX: 20,
            triggerOffsetY: 20,
            mobile: {
              triggerSize: 'small',
              triggerPositionX: 'right',
              triggerPositionY: 'bottom',
              triggerOffsetX: 10,
              triggerOffsetY: 10,
              triggerRadius: '50%'
            }
          });
        };
        b.appendChild(s);
      })();
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

const PendoCdnTag = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `
      (function(apiKey){
        (function (p, e, n, d, o) {
          var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          })(v[w]);
          y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');    pendo.initialize({             
        });
    })('85b322b5-66ec-4797-5317-c6e97e49f9a3');
    `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return null;
};

const BodyScripts = () => (
  <>
    <ScriptTag />
    <PendoCdnTag />
    <ExtendScriptTag />
  </>
);


ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <SettingsProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SettingsProvider>
      </StyledEngineProvider>
    </HelmetProvider>
    <BodyScripts />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
