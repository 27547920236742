import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Divider, Drawer, Hidden, Typography } from "@mui/material";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import PlusIcon from "../../icons/Plus";
import CogIcon from "../../icons/Cog";
import UserIcon from "../../icons/User";
import ClipboardIcon from "../../icons/Clipboard";
import CreditCardIcon from "../../icons/CreditCard";

import Logo from "../Logo";
import NavSection from "../NavSection";
import useAuth from "../../hooks/useAuth";
import { AppConfig } from "src/constants/AppConfig";

const DashboardSidebar = (props: { onMobileClose: () => void; openMobile: boolean }) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { state: authState } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const sections = [
    {
      key: "main",
      items: [
        {
          title: "Dashboard",
          path: "/",
          icon: <ChartSquareBarIcon fontSize="small" />,
        },
        {
          title: "New device return",
          path: "/devicereturn",
          icon: <PlusIcon fontSize="small" />,
        },
        {
          title: "Settings",
          path: "/settings",
          icon: <CogIcon fontSize="small" />,
        },
      ],
    },
  ];

  if (authState.isAuthenticated && authState.user.role === "admin") {
    if (authState.user.sidebar) {
      sections[0].items.push({
        title: "API",
        path: "/api",
        icon: <ClipboardIcon fontSize="small" />,
      });
    }
    sections[0].items.push({
      title: "Users",
      path: "/users",
      icon: <UserIcon fontSize="small" />,
    });
  }

  sections[0].items.push({
    title: "Billing",
    path: "/billing",
    icon: <CreditCardIcon fontSize="small" />,
  });

  if (authState.isAuthenticated && authState.user.role === "admin") {
    sections[0].items.push({
      title: "Okta Configuration",
      path: "/oktaview",
      icon: <PlusIcon fontSize="small" />,
    });
  }

  if (authState.isAuthenticated && authState.user.role === "admin") {
    sections[0].items.push({
      title: "Azure Configuration",
      path: "/azureview",
      icon: <PlusIcon fontSize="small" />,
    });
  }
  if (
    authState.isAuthenticated &&
    (authState.user.role === "admin" ||
      (authState.user.role === "member" && authState.user.is_warehouse_user))
  ) {
    sections[0].items.push({
      title: "Warehousing",
      path: "/warehousing",
      icon: <ChartSquareBarIcon fontSize="small" />,
    });
  }

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Hidden lgUp>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 30,
                width: 130,
              }}
            />
          </RouterLink>
        </Box>
      </Hidden>
      <Box sx={{ p: 2 }}>
        {sections.map((section) => (
          <NavSection pathname={location.pathname} {...section} />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Divider />
        <Box sx={{ pt: 2, px: 3, pb: 1 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Questions? Need help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Email <a target="_blank" href={`mailto:${AppConfig.CONFIGURATION.APP_EMAILID}`}>{AppConfig.CONFIGURATION.APP_EMAILID}</a>
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption">
              <a
                href={AppConfig.CONFIGURATION.TERM_PAGE_URL}
                target="_blank"
                style={{ color: "#888", fontWeight: "normal" }}
              >
                Terms
              </a>{" "}
              ·{" "}
              <a
                href={AppConfig.CONFIGURATION.PRIVACY_PAGE_URL}
                target="_blank"
                style={{ color: "#888", fontWeight: "normal" }}
              >
                Privacy
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
