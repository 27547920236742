import merge from "lodash/merge";
import { createMuiTheme, responsiveFontSizes } from "@mui/material/styles";
import { THEMES } from "../constants";
import { lightShadows } from "./shadows";
import "./font.css";

const baseOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: 0,
          boxShadow: "rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
      styleOverrides: {
        root: {
          paddingBottom: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 0,
          boxShadow: "rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 12,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "auto",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          whiteSpace: 'nowrap'
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '"Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#f4f5f7",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#007a8a",
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#9641e5", // Used for the "Box shipped" laptop return status
      },
      tertiary: {
        contrastText: "#ffffff",
        main: "#2e2be2", // Used for the "Laptop shipped" laptop return status
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50", // Used for the "Delivered" laptop return status
      },
      text: {
        primary: "#172b4d",
        secondary: "#6b778c",
        navy: "#001e42",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#e68900", // Used for the "Awaiting shipment" (pending) laptop return status
      },
      cancelled: {
        contrastText: "#ffffff",
        main: "#ca2c92", // Used for the "Cancelled" laptop return status
      },
      alert: {
        yellow: '#fdc147'
      }
    },
    shadows: lightShadows,
  },
};

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {
      ...(config.roundedCorners && {
        shape: {
          borderRadius: 16,
        },
      }),
    })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
