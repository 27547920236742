import { useEffect } from "react";
import NProgress from "nprogress";
import { Box } from "@mui/material";
import "nprogress/nprogress.css";
import "./LoadingScreenNprogress.css";

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
      }}
    />
  );
};

export default LoadingScreen;
