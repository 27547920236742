import React, { ReactNode } from "react";
import { Alert, Box } from "@mui/material";

interface ChilderProps {
  children: ReactNode;
}

export default class OutdatedAppChunkLoadBoundary extends React.Component<ChilderProps, { chunkLoadError: boolean }> {
  state = {
    chunkLoadError: false,
  };

  static getDerivedStateFromError(error: any) {
    // Old browser tabs will attempt to load chunks upon app navigation that may no
    // longer exist after a release. If that happens, show a friendly message.
    return { chunkLoadError: error && error.name === "ChunkLoadError" };
  }

  componentDidCatch(error: any) {
    if (!error || error.name !== "ChunkLoadError") {
      // Re-throw anything that's not a chunk load error so that we don't show the message below.
      throw error;
    }
  }

  render() {
    if (this.state.chunkLoadError) {
      return (
        <Box sx={{ p: 2, display: "flex", height: "100vh", alignItems: "center", justifyContent: "center" }}>
          <Alert severity="info" sx={{ mt: 2 }}>
            The Retriever portal has been updated. Please refresh your browser.
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

export class NotFoundErrorBoundary extends React.Component<ChilderProps, { error: boolean }> {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error: any) {
    return { error: error?.response?.status === 404 };
  }

  componentDidCatch(error: any) {
    if (error?.response?.status !== 404) {
      // Re-throw anything that we don't handle so that we don't show the message below.
      throw error;
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Box sx={{ p: 2, display: "flex", height: "100vh", alignItems: "center", justifyContent: "center" }}>
          <Alert severity="error" sx={{ mt: 2 }}>
            We could not find what you were looking for.
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}
