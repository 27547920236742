import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import Logo from "../../components/Logo";
import LoginPasswordless from "../../components/authentication/login/LoginPasswordless";

const Login = () => (
  <>
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="sm" sx={{ py: "80px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <Logo
            sx={{
              height: 100,
              width: 220,
            }}
          />
        </Box>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Log in
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  We&apos;ll email you a magic link for a password-free login.
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LoginPasswordless />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  </>
);

export default Login;
